<template>
    <div class="DeviceDetail">
        <el-card class="box-card">
            <i class="el-icon-close cha" @click="$router.go(-1)"></i>

            <div class="clearfix">
                基本信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">编号：</div>
                    <div class="info">{{ sparePartInfo.id }}</div>
                </div>
                <div class="item">
                    <div class="label">名称：</div>
                    <div class="info">{{ sparePartInfo.spareName }}</div>
                </div>
                <div class="item">
                    <div class="label">上级分类：</div>
                    <div class="info">{{ sparePartInfo.superClassName }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">详细分类：</div>
                    <div class="info">{{ sparePartInfo.subClassName }}</div>
                </div>
                <div class="item">
                    <div class="label">规格型号：</div>
                    <div class="info">{{ sparePartInfo.spareType }}</div>
                </div>
                <div class="item">
                    <div class="label">单位:</div>
                    <div class="info">{{ sparePartInfo.unit }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">数量：</div>
                    <div class="info">{{ sparePartInfo.number }}</div>
                </div>
                <div class="item">
                    <div class="label">单价(元)：</div>
                    <div class="info">{{ sparePartInfo.price }}</div>
                </div>
                <div class="item">
                    <div class="label">总价：</div>
                    <div class="info">{{ sparePartInfo.totalMoney }}</div>
                </div>
            </div>

            <div class="df">
                <div class="item">
                    <div class="label">出厂日期：</div>
                    <div class="info">{{ sparePartInfo.producedDate }}</div>
                </div>
                <div class="item">
                    <div class="label">保质期/有限期(年）：</div>
                    <div class="info">{{ sparePartInfo.expirationDate }}</div>
                </div>
                <div class="item">
                    <div class="label">状态：</div>
                    <div class="info">{{ sparePartInfo.statustext }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">所在库房：</div>
                    <div class="info">{{ sparePartInfo.whCodeName }}</div>
                </div>
                <div class="item">
                    <div class="label">存放位置：</div>
                    <div class="info">{{ sparePartInfo.location }}</div>
                </div>
                <div class="item">
                    <div class="label">录入员账号：</div>
                    <div class="info">{{ sparePartInfo.account }}</div>
                </div>

            </div>
            <div class="df">
                <div class="item">
                    <div class="label">录入时间：</div>
                    <div class="info">{{ sparePartInfo.recordTime }}</div>
                </div>
                <div class="item">
                    <div class="label">备注：</div>
                    <div class="info">{{ sparePartInfo.remark }}</div>
                </div>
            </div>
        </el-card>

        <el-card>
            <div class="clearfix">
                选型照片
            </div>
            <div class="df-img">

                <div class="info" v-if="imgsList">
                    <div class="block" v-for="(item, index) in imgsList" :key="index">
                        <!-- <span class="demonstration">自定义</span> -->
                        <el-image :src="item" :preview-src-list="imgsList">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
        </el-card>

    </div>
</template>

<script>

import { querySparePart } from '@/api/sparepart.js'
export default {
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            sparePartInfo: {
                spareName: '',
                spareClass: '',
                spareType: '',
                unit: '',
                number: null,
                price: '',
                producedDate: '',
                expirationDate: null,
                spareImg: '',
                status: null,
                location: '',
                remark: '',
                spareOptionValue: ''
            },

            imgsList: [],
            path: process.env.VUE_APP_URL + this.UploadPath

        }
    },
    mounted() {
        console.log(this.id)
        this.id && this.loadDeviceInfo()
    },
    methods: {
        //根据id查询设备信息
        async loadDeviceInfo() {
            await querySparePart(this.id).then(res => {
                if (res.code === '000000') {
                    this.sparePartInfo = res.t
                    this.sparePartInfo.status_text = this.sparePartInfo.status === "00" ? "充足" : "待补充"
                    if (this.sparePartInfo.spareImg != null && this.sparePartInfo.spareImg != '') {
                        const list1 = this.sparePartInfo.spareImg.split(";")
                        list1.forEach(item => {
                            if (item != '' && item != null) {
                                const obj = this.path + item
                                this.imgsList.push(obj)
                            }
                        })
                    }
                }
            })
        },

    }
}

</script>


<style lang="scss" scoped>
.DeviceDetail {
    .el-card {
        position: relative;
        text-align: left;
        margin-bottom: 15px;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .clearfix {
        margin-bottom: 20px;
        font-size: 16px;
        color: #1d2129;
    }


    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }

    .df:last-child {
        margin-bottom: 0;
    }

    .df {
        display: flex;
        margin-bottom: 16px;

        .item {
            width: 33.333333%;
            display: flex;
            font-size: 14px;
            color: #4e5969;

            .label {
                margin-right: 8px;
            }

            .info {
                // width: 100%;
                float: left;
            }
        }
    }

    .df-img {
        width: 100%;
        margin-bottom: 16px;

        .info {
            width: 100%;
            // float: left;
            display: flex;

            .block {
                width: 25%;
                margin-right: 10px;

                .el-image {
                    width: 100%;
                    height: 100%;
                }
            }

        }
    }


}
</style>